<template>
    <ion-page>
        <ion-content :fullscreen="true" style="background: #000">
            <div class="control-head">
                <div class="return" on-touch="returnSelect" @click="returnSelect"><ion-icon src="/assets/icon/arrow-back.svg"></ion-icon></div>
            </div>
            <ion-grid style="margin: 0;padding: 0;">
                <iframe :src="linkPage" style="width: 100%; height: 100vh;border:0"></iframe>
            </ion-grid>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonGrid, IonContent, IonIcon } from '@ionic/vue';

export default  {
    name: 'Videos',
    components: { IonGrid, IonContent, IonPage, IonIcon },
    data() {
        return {
            linkPage: 'https://media.ukraine-inform.com/my-videos-not-public-clear/?isUser='
        }
    },
    created() {
        const userId = localStorage.getItem('user');
        this.linkPage = this.linkPage + userId;
        console.log(this.linkPage);
    },
    methods: {
        returnSelect() {
            window.location.href = '/select';
        },
    }
}
</script>
<style>
.control-head {
    position: fixed;
    bottom: 15px;
    z-index: 999;
    left: 15px;
    font-size: 36px;
    background: #0037ffad;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    text-align: center;
    padding-top: 4px;
}
</style>
